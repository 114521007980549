import { render, staticRenderFns } from "./IconOrderStatusSent.vue?vue&type=template&id=14224fab&"
import script from "./IconOrderStatusSent.vue?vue&type=script&lang=js&"
export * from "./IconOrderStatusSent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports